.maindiv{
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.firstDiv{
    border: 2px solid rgb(226, 31, 31);
    width: 60%;
    height: 70%;
}